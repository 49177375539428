<template>
  <v-container>
    
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto"
        outlined
      >
        <v-row>

          <v-col
          cols="12"
            md="12"
          >

            <v-autocomplete
              v-model="postForm.user"
              :items="dataListUser"
              label="Pilih User"
              item-text="showname"
              item-value="id"
              :disabled="isEdit"
              :rules="[rules.required]"
            ></v-autocomplete>
      
          </v-col>

          <v-col
          cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="selHolding"
              :items="dataListHolding"
              label="Pilih Holding"
              @change="getBasedHolding()"
            ></v-autocomplete>   
      
          </v-col>

          <v-col
          cols="12"
            md="12"
          >

           <v-autocomplete
              v-model="postForm.hrorganization"
              :items="dataListOrganization"
              label="Pilih Organisasi"
              item-text="text"
            item-value="value"
            :disabled="false"
            :rules="[rules.required]"
            ></v-autocomplete>      
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="postForm.prefix"
              label="Prefix"
            ></v-text-field>  
          </v-col>

           <v-col
          cols="12"
            md="8"
          >

          <v-autocomplete
              v-model="postForm.hrposition"
              :items="dataListParentPos"
              label="Pilih Jabatan"
              item-text="pos_name"
            item-value="id"
            :disabled="false"
            :rules="[rules.required]"
            ></v-autocomplete>   

          </v-col>

          <v-col
          cols="12"
            md="6"
          >
            <v-autocomplete
              v-model="postForm.rolename"
              :items="listRole"
              label="Pilih Role"
              :rules="[rules.required]"
            ></v-autocomplete>   
      
          </v-col>
        </v-row>
        <v-row>

          <v-col
            cols="12"
            md="4"
          >
            <v-dialog
                ref="SelBDateStart"
                v-model="selDateStart"
                :return-value.sync="postForm.dateStart"
                persistent
                width="290px"
              >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="postForm.dateStart"
                  label="Tgl Mulai Jabatan"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  :rules="[rules.required]"
                ></v-text-field>
              </template>
                <v-date-picker 
                v-model="postForm.dateStart"
                >
                <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="selDateStart = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.SelBDateStart.save(postForm.dateStart)">OK</v-btn>
                </v-date-picker>
                
            </v-dialog>
            
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-dialog
                ref="SelBDateEnd"
                v-model="selDateEnd"
                :return-value.sync="postForm.dateEnd"
                persistent
                width="290px"
              >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="postForm.dateEnd"
                  label="Tgl Akhir Jabatan"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                  :disabled="boolDateEnd"
                ></v-text-field>
              </template>
                <v-date-picker 
                v-model="postForm.dateEnd"
                >
                <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="selDateEnd = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.SelBDateEnd.save(postForm.dateEnd)">OK</v-btn>
                </v-date-picker>
                
            </v-dialog>
            
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <v-switch
              v-model="postForm.isUnlimitedDate"
              label="isUnlimitedDate"
              @change="onUnlimitCheck"
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="postForm.isMainPosition"
              label="isMainPosition"
            ></v-switch>
          </v-col>
         
          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="postForm.isActive"
              label="isActive"
            ></v-switch>
          </v-col>

        </v-row>
        <v-row>
          <v-col>

            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              @click="goBack()"
            >
              Batal
            </v-btn>

            <v-btn v-if="!isEdit" :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Simpan
            </v-btn>
            <v-btn v-else :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              Ubah
            </v-btn>
      
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn v-if="!isEdit" color="blue darken-1" @click="insertData()">Submit</v-btn>
            <v-btn v-else color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import { getActiveUser } from '@/api/admin'
import { getListPositionsActiveByHolding } from '@/api/hrpositions'
import { getALLHROrganizationByHolding } from '@/api/hrorganization'
import { insertHRUserPositions, getHRUserPositionsByID, updateHRUserPositions } from '@/api/hruserpositions'
import { getSettingParamEofficeData, updateSettingParam } from '@/api/settingparam'

import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const defaultForm = {
  pos_name: '',
  is_headunit: 0, 
  isActive: 0, 
  parent: '',
  hrorganization: '',
  rolename:'',
  dateStart: '',
  dateEnd: '',
  isUnlimitedDate: 0, 
  isMainPosition: 0
}

export default {
  name: 'FormUserPos',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.'
      },
      uploadPercentage: 0,
      isupLoading: false,
      isLoading: false,
      dialogConfirm: false,
      selDateStart: false,
      selDateEnd: false,
      boolDateEnd: false,
      dataListParentPos: [],
      dataListUser: [],
      dataListOrganization: [],
      dataListHolding: ["KSK", "DSU", "FAK", "FKK", "FMA", "FSK", "FSL", "MJL", "MSL"],
      selHolding: '',
      boolDateEnd: false,
      listRole: ['admin', 'developer', 'staff', 'sekretaris', 'asisten', 'kadiv', 'kabag', 'manager', 'GM', 'director', 'VP', 'presdir', 'komite']
    }
  },
  created () {
    this.getUsersData()

    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id
      this.fetchData(id)
    }
    else {
      this.postForm = Object.assign({})
    }
  },
  destroyed () {
  },
  mounted () {
    // var now = moment().format('YYYY-MM-DD')
  },
  computed: {

  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    resetForm () {
      this.$refs.form.reset()
    },
    getBasedHolding(){
      const self = this
      // console.log(this.selHolding)
      this.getOrganizationData()
      this.getPositionData()
    },
    getUsersData(){
      const self = this
      getActiveUser().then(response => {
        var rData = response.data
        // console.log(rData)
        if (rData !== null) {
          rData.forEach(element => {
            element['showname'] = '[' + element['id'] + '] ' + element['fullname'] + ' <' + element['email'] + '>'
          })
          self.dataListUser = rData
        }
      });  
    },
    getPositionData(){
      const self = this
      self.dataListParentPos =[]
      getListPositionsActiveByHolding(this.selHolding).then(response => {
        var rData = response.data
        // console.log(rData)
        // console.log('getListPositionsActiveByHolding: ', rData.length)
        if (rData !== null) {
          self.dataListParentPos = rData
        }
      });  
    },
    getOrganizationData(){
      const self = this
      self.dataListOrganization = []
      getALLHROrganizationByHolding(this.selHolding).then(response => {
        var rData = response.data
        if(rData != null){
          var array = []
          // console.log('getALLHROrganizationByHolding: ', rData.length)
          rData.forEach(element => {
            var tmp = { value: element.id, text: element.level + ' ' + element.structure_name + ' ('+ element.unit_code + ')' + ' ['+ element.holding + ']' }
            array.push(tmp)
          })
          self.dataListOrganization = array
        }
        
      });  
    },
    onUnlimitCheck(){
      if(this.postForm.isUnlimitedDate == true){
        this.boolDateEnd = true
      }else{
        this.boolDateEnd = false
      }
    },
    saveAddForm () {
      const vm = this

      if(vm.$refs.form.validate()){

        if(this.postForm.isUnlimitedDate == true){
          this.postForm.dateEnd = null
        }
        if (this.postForm.dateStart != "0000-00-00") {
          this.postForm.dateStart = moment(this.postForm.dateStart).format('YYYY-MM-DD')
          // console.log(this.postForm.dateStart)
        }
        if (this.postForm.dateEnd != "0000-00-00" && this.postForm.dateEnd != null) {
          this.postForm.dateEnd = moment(this.postForm.dateEnd).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }
        
        // console.log(vm.postForm)
        delete this.postForm.id
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    saveEditForm () {
      const vm = this
      
      if(vm.$refs.form.validate()){

        if(this.postForm.isUnlimitedDate == true){
          this.postForm.dateEnd = null
        }
        if (this.postForm.dateStart != "0000-00-00") {
          this.postForm.dateStart = moment(this.postForm.dateStart).format('YYYY-MM-DD')
          // console.log(this.postForm.dateStart)
        }
        if (this.postForm.dateEnd != "0000-00-00" && this.postForm.dateEnd != null) {
          this.postForm.dateEnd = moment(this.postForm.dateEnd).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }
        
        // console.log(vm.postForm)
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    
    fetchData (id) {
      const vm = this
      vm.isLoading = true
      getHRUserPositionsByID(id).then(response => {
        this.postForm = response.data
        // console.log(this.postForm)
 
        if(this.postForm.user != null){
          this.postForm.user = this.postForm.user.id
        }
        if(this.postForm.hrposition != null){
          this.postForm.hrposition = this.postForm.hrposition.id
        }

        if(this.postForm.hrorganization != null){
          this.postForm.hrorganization = this.postForm.hrorganization.id
        }

        this.onUnlimitCheck()

        if (this.postForm.dateStart != "0000-00-00" && this.postForm.dateStart != null) {
          this.postForm.dateStart = moment(this.postForm.dateStart).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }

        if (this.postForm.dateEnd != "0000-00-00" && this.postForm.dateEnd != null) {
          this.postForm.dateEnd = moment(this.postForm.dateEnd).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }

        delete this.postForm.created_at  
        delete this.postForm.updated_at 

        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    insertData () {
      const vm = this
      vm.dialogConfirm = false

      // console.log('insert:',vm.postForm)
      vm.isLoading = true
      insertHRUserPositions(vm.postForm).then(response => {
        var data = response.data
        vm.postForm = data

        if (this.postForm.dateStart != "0000-00-00" && this.postForm.dateStart != null) {
          this.postForm.dateStart = moment(this.postForm.dateStart).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }

        if (this.postForm.dateEnd != "0000-00-00" && this.postForm.dateEnd != null) {
          this.postForm.dateEnd = moment(this.postForm.dateEnd).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }

        // Set parameter to 1
        getSettingParamEofficeData().then(response => {
          var data = response.data
          if(data){
            var idParam = data[0].id
            var param = {
              value: 100 //100 times = kira2 jumlah pegawai login
            }
            updateSettingParam(param, idParam).then(respupd => {
              // console.log(respupd.data)
            }).catch(error => {
              // console.log(error)
            })
          }
        }).catch(error => {
          // console.log(error)
        })
        
        vm.$router.push('/pgw/userpos')
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false

      var idb = vm.postForm.id
      // console.log('id Data: ', idb)
      vm.isLoading = true
      // console.log('update:',vm.postForm)

      updateHRUserPositions(vm.postForm, idb).then(response => {
        // console.log('Data', response.data)
        var data = response.data
        vm.postForm = data

        if (this.postForm.dateStart != "0000-00-00" && this.postForm.dateStart != null) {
          this.postForm.dateStart = moment(this.postForm.dateStart).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }

        if (this.postForm.dateEnd != "0000-00-00" && this.postForm.dateEnd != null) {
          this.postForm.dateEnd = moment(this.postForm.dateEnd).format('YYYY-MM-DD')
          // console.log(this.postForm.dateEnd)
        }

        // Set parameter to 1
        getSettingParamEofficeData().then(response => {
          var data = response.data
          if(data){
            var idParam = data[0].id
            var param = {
              value: 1
            }
            updateSettingParam(param, idParam).then(respupd => {
              // console.log(respupd.data)
            }).catch(error => {
              // console.log(error)
            })
          }
        }).catch(error => {
          // console.log(error)
        })

        vm.$router.push('/pgw/userpos')
        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Update Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    
  }
}

</script>
